import { useState, useEffect } from 'react'
import { DateTime } from 'luxon'
import * as yup from 'yup'
import { useDebouncedCallback } from 'use-debounce'
import firebase from '@/firebase/clientApp'
import sanitize from '@/helpers/Sanitize'
import { niceType } from '@/helpers/Typography'
import { logError } from '@/helpers/Logging'

//https://gist.github.com/bitfabrikken/25d239d41dc7e113b70fd658c7dc063d

class User {
  constructor (uid, createdAt, username, name, bio, pronouns, location, elsewhereOne, elsewhereTwo, elsewhereThree, avatarURL) {
    let sanitizedName = sanitize(name)
    let sanitizedUsername = sanitize(username)
    this.uid = uid
    this.createdAt = createdAt
    this.name = sanitizedName
    this.username = sanitizedUsername
    this.bio = sanitize(bio)
    this.pronouns = sanitize(pronouns)
    this.location = sanitize(location)
    this.elsewhereOne = sanitize(elsewhereOne)
    this.elsewhereTwo = sanitize(elsewhereTwo)
    this.elsewhereThree = sanitize(elsewhereThree)
    this.avatarURL = sanitize(avatarURL)
    this.displayName = sanitizedName ? sanitizedName : sanitizedUsername
  }

  toString() {
    return '[User]' + this.uid
  }

  json() {
    let payload = {
      uid: this.uid,
      username: this.username,
      displayName: this.displayName,
      createdAt: this.createdAt.toJSON()
    }

    if (this.bio) {
      payload.bio = this.bio
    }

    if (this.pronouns) {
      payload.pronouns = this.pronouns
    }

    if (this.location) {
      payload.location = this.location
    }

    if (this.elsewhereOne) {
      payload.elsewhereOne = this.elsewhereOne
    }

    if (this.elsewhereTwo) {
      payload.elsewhereTwo = this.elsewhereTwo
    }

    if (this.elsewhereThree) {
      payload.elsewhereThree = this.elsewhereThree
    }

    if (this.elsewhereThree) {
      payload.elsewhereThree = this.elsewhereThree
    }

    if (this.updatedAt) {
      payload.updatedAt = this.updatedAt.toJSON()
    }

    return payload
  }
}

export const userConverter = {
  toFirestore: function(user) {
      return {
        username: user.username,
        bio: user.bio,
        pronoun: user.pronoun,
        avatarURL: user.avatarURL
      }
  },
  fromFirestore: function(snapshot, options) {
    const data = snapshot.data(options)
    let createdAt = DateTime.fromSeconds(data.createdAt.seconds)
    return new User(snapshot.id, createdAt, data.username, data.name, data.bio, data.pronouns, data.location, data.elsewhereOne, data.elsewhereTwo, data.elsewhereThree, data.avatarURL)
  }
}

//https://stackoverflow.com/questions/52850099/what-is-the-reg-expression-for-firestore-constraints-on-document-ids/52850529#52850529
export async function createUser (authedUser, username) {
  if (!authedUser || !username) {
    return
  }

  const db = firebase.firestore()
  const batch = db.batch()

  var lowercaseUsername = username.toLowerCase()

  const Collection = db.collection('users')
  const userRef = Collection.doc(authedUser.uid)
  batch.set(userRef, {
    uid: authedUser.uid,
    username: lowercaseUsername,
    avatarURL: authedUser.photoURL || null,
    createdAt: firebase.firestore.FieldValue.serverTimestamp(),
  })

  const Index = db.collection('indexes')
  const indexRef = Index.doc(`users/usernames/${lowercaseUsername}`)
  batch.set(indexRef, {
    uid: authedUser.uid
  })

  await batch.commit()
}

export const MAX_LENGTH_SHORT = 50
export const MAX_LENGTH_MEDIUM = 100
export const MAX_LENGTH_LONG = 500

export const SettingsSchema = yup.object().shape({
  name: yup.string().notRequired().trim().max(MAX_LENGTH_SHORT, () => `Your name must be less than ${MAX_LENGTH_SHORT} characters.`),
  pronouns: yup.string().notRequired().trim().max(MAX_LENGTH_SHORT, () => `Prefered pronouns must be less than ${MAX_LENGTH_SHORT} characters.`),
  bio: yup.string().notRequired().trim().max(MAX_LENGTH_LONG, () => `Your bio must be less than ${MAX_LENGTH_LONG} characters.`),
  location: yup.string().notRequired().trim().max(MAX_LENGTH_SHORT, () => `Your location must be less than ${MAX_LENGTH_SHORT} characters.`),
  elsewhereOne: yup.string().notRequired().url(() => `Elsewhere fields must be a valid URL.`).trim().max(MAX_LENGTH_MEDIUM, () => `URLs must be less than ${MAX_LENGTH_MEDIUM} characters.`),
  elsewhereTwo: yup.string().notRequired().url(() => `Elsewhere fields must be a valid URL.`).trim().max(MAX_LENGTH_MEDIUM, () => `URLs must be less than ${MAX_LENGTH_MEDIUM} characters.`),
  elsewhereThree: yup.string().notRequired().url(() => `Elsewhere fields must be a valid URL.`).trim().max(MAX_LENGTH_MEDIUM, () => `URLs must be less than ${MAX_LENGTH_MEDIUM} characters.`),
})

export async function updateUser (uid, username, profile) {
  const valid = await SettingsSchema.isValid(profile)

  if (valid) {
    const Collection = firebase.firestore().collection('users')
    const userRef = Collection.doc(uid)

    profile['uid'] = uid
    profile['username'] = username
    profile['updatedAt'] = firebase.firestore.FieldValue.serverTimestamp()

    // Sanitize our input
    profile['name'] = sanitize(profile.name)
    profile['pronouns'] = sanitize(profile.pronouns)
    profile['bio'] = niceType(sanitize(profile.bio))
    profile['location'] = sanitize(profile.location)
    profile['elsewhereOne'] = sanitize(profile.elsewhereOne)
    profile['elsewhereTwo'] = sanitize(profile.elsewhereTwo)
    profile['elsewhereThree'] = sanitize(profile.elsewhereThree)

    return await userRef.update(profile)
    .then(() => {
      return {
        success: true,
        error: null
      }
    })
    .catch((error) => {
      logError(error, { type: 'model', name: 'user' }, { username, profile })
      return {
        success: false,
        error: error
      }
    })
  } else {
    let err = new Error('There was an error updating the user')
    logError(err, { type: 'model', name: 'user' }, { username, profile })
    return err
  }
}

/// https://stackoverflow.com/questions/47543251/firestore-unique-index-or-unique-constraint
export function useIsValidateUsername(username) {
  const maxLength = 15
  const debounceDelay = 500
  const usernamePattern = new RegExp(/^\w+$/) /// letters, numbers, underscore
  const reservedUsernames = ["about", "account", "accounts", "activity", "administrator", "all", "announcements", "anywhere", "apple", "api", "api_rules", "api_terms", "apirules", "apps", "auth", "badges", "blog", "business", "buttons", "create", "contacts", "chrome", "devices", "direct_messages", "download", "downloads", "edit_announcements", "faq", "favorites", "find_sources", "find_users", "firefox", "followers", "following", "friend_request", "friendrequest", "friends", "help", "home", "http", "https", "inbox", "invitations", "invite", "ios", "jobs", "list", "login", "logo", "logout", "marks", "mac", "mentions", "messages", "new", "newsletters", "notifications", "oauth", "privacy", "profile", "replies", "rules", "safari", "search", "settings", "share", "sharemark", "sharemarks", "signup", "signin", "terms", "tos", "topic", "topics", "welcome", "widgets", "write", "www"]

  const [isValid, setIsValid] = useState(false)
  const [loading, setIsLoading] = useState(false)
  const [error, setError] = useState(null)
  const [debouncedFirebaseRequest, cancelFirebaseRequest] = useDebouncedCallback(lookupUsername, debounceDelay)
  const [debouncedShowLoading, cancelShowLoading] = useDebouncedCallback(showLoading, debounceDelay)

  useEffect(() => {
    try {
      // Everytime we enter this, reset.
      setError(null)
      setIsValid(false)

      // No username isn't an error, it's just not valid
      if (!username) {
        cancelFirebaseRequest()
        return
      }

      // Check for invalid chars
      if (!usernamePattern.test(username)) {
        cancelFirebaseRequest()
        throw new Error('Only letters, numbers, and underscores allowed.')
      }

      // Check our length
      if (username.length > maxLength) {
        cancelFirebaseRequest()
        let excess = Math.abs(maxLength - username.length)
        let characters = excess == 1 ? 'character' : 'characters'
        throw new Error(`${excess} ${characters} too long.`)
      }

      // Make sure the username isn't a reserved word.
      if (reservedUsernames.indexOf(username) > -1) {
        cancelFirebaseRequest()
        throw new Error(`That username is taken. Please try something else.`)
      }

      // If we've got this far, it appears we have
      // a proper username, ask firebase if it's taken
      debouncedFirebaseRequest(username)

      return () => {
        cancelShowLoading()
        cancelFirebaseRequest()
      }
    } catch (error) {
      setIsLoading(false)
      setIsValid(false)
      setError(error)
    }
  }, [username])

  function showLoading() {
    setIsLoading(true)
  }

  function lookupUsername(username) {
    debouncedShowLoading()
    setError(null)

    firebase.firestore()
      .collection('/indexes/users/usernames/')
      .doc(username.toLowerCase()) // check usernames as lowercased
      .get({ source: 'server' }) /// always hit the server while checking for a username to prevent cache hits
      .then(doc => {
        setIsLoading(false)
        cancelShowLoading()
        if (!doc.exists) {
          /// If the result is empty, no one has this username
          setError(null)
          setIsValid(true)
        } else {
          /// If it's not empty, someone has the username
          setError(new Error(`That username is taken. Please try something else.`))
          setIsValid(false)
        }
      })
  }

  return [isValid, loading, error]
}

export function useGetUserProfileByUsername(username) {
  const [profile, setProfile] = useState(null)
  const [loading, setIsLoading] = useState(true)
  const [error, setError] = useState(null)

  useEffect(() => {
    if (!username) {
      setIsLoading(false)
      return
    }
    try {
      setIsLoading(true)
      firebase.firestore().collection("users")
        .where("username", "==", username)
        .withConverter(userConverter)
        .limit(1)
        .get()
        .then(function(querySnapshot) {
          if (querySnapshot.empty) {
            setProfile(profile)
            setError(new Error('No profile exists for this username.'))
            setIsLoading(false)
            return
          }
          querySnapshot.forEach(doc => {
            let profile = doc.data({ serverTimestamps: 'estimate' })
              setProfile(profile)
              setError(error)
              setIsLoading(false)
          });
      })
    } catch (error) {
      logError(error, { type: 'model', name: 'user' }, { username })
      setProfile(null)
      setError(error)
      setIsLoading(false)
    }
    return
  }, [username])

  return { profile, loading, error }
}


export function useGetUserByUID(uid) {
  const [data, setQueryData] = useState(null)
  const [loading, setIsLoading] = useState(false)
  const [error, setError] = useState(null)

  useEffect(() => {
    setError(null)
    setQueryData(null)

    if (!uid) {
      setIsLoading(false)
      return
    }
    try {
      setIsLoading(true)
      var unsubscribe = firebase.firestore()
        .collection('users')
        .doc(uid)
        .withConverter(userConverter)
        .onSnapshot(doc => {
          setQueryData(doc.data({ serverTimestamps: 'estimate' }))
          setIsLoading(false)
        }, error => {
          logError(error, { type: 'model', name: 'user' }, { uid })
          throw error
        })
    } catch (error) {
      logError(error, { type: 'model', name: 'user' }, { uid })
      setIsLoading(false)
      setQueryData(null)
      setError(error)
    }
    return () => {
      if (unsubscribe) unsubscribe()
    }
  }, [uid])

  return [data, loading, error]
}

export async function getUserByUID(uid) {
  return firebase.firestore()
    .collection('users')
    .doc(uid)
    .withConverter(userConverter)
    .get()
    .then(doc => {
      if (!doc.exists) {
        let e = new Error('User did not exist for that UID')
        logError(e, { type: 'model', name: 'user' }, { uid })
        return {
          success: false,
          error: err
        }
      }
      return {
        success: true,
        data: doc.data({ serverTimestamps: 'estimate' })
      }
    })
    .catch(err => {
      let e = new Error('User did not exist for that UID')
      logError(e, { type: 'model', name: 'user' }, { uid })
      return {
        success: false,
        error: err
      }
    })
}

export async function getUserByUsername(username) {
  try {
    let snapshots = await firebase.firestore()
                        .collection('users')
                        .where('username', '==', username)
                        .limit(1)
                        .withConverter(userConverter)
                        .get()

    if (snapshots.empty) {
      let e = new Error(`User did not exist for username: ${username}`)
      logError(e, { type: 'model', name: 'user' }, { username })
      return {
        success: false,
        error: e
      }
    }

    let users = []
    snapshots.forEach(doc => users.push(doc.data({ serverTimestamps: 'estimate' })))
    return {
      success: true,
      data: users[0]
    }
  } catch (err) {
    logError(err, { type: 'model', name: 'user' }, { username })
    return {
      success: false,
      error: err
    }
  }
}
