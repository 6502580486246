import firebase from '@/firebase/clientApp'

const isProd = process.env.NODE_ENV == 'production'

export const EVENT = {
  signUp: 'sign_up',
  logIn: 'log_in',
  saveProfile: 'save_profile',
  shareProfile: 'share_profile'
}

export const logPageView = (pageName, params) => {
  if (!isProd) return
  firebase.analytics().logEvent('screen_view', { screen_name: pageName })
}

export function logEvent(name, params) {
  if (!isProd) return
  firebase.analytics().logEvent(name, params)
}