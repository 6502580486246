import { createContext, useContext, useState, useEffect } from 'react'
import { useAuthenticatedUser } from '@/context/authContext'
import firebase from '@/firebase/clientApp'
import { logError } from '@/helpers/Logging'

let auth = firebase.auth()

export const FeatureGroupContext = createContext()
FeatureGroupContext.displayName = 'FirebaseFeatureGroupContext'

export default ({ children }) => {
  const { authIsLoading, authenticatedUser } = useAuthenticatedUser()
  const [isBeta, setIsBeta] = useState(false)
  const [isStaff, setIsStaff] = useState(false)
  const [isLoading, setIsLoading] = useState(true)
  const [lastCommitted, setLastCommitted] = useState(null)

  useEffect(() => {
    if (authIsLoading) {
      return
    }

    if (!authenticatedUser) {
      setIsBeta(false)
      setIsLoading(false)
      return
    }

    /// Initially fetch the claims for the current user.
    async function fetchClaims() {
      let idTokenResult = await auth.currentUser.getIdTokenResult(false)
      let claims = idTokenResult.claims
      setIsBeta(claims.isBeta || false)
      setIsStaff(claims.isStaff || false)
      setIsLoading(false)
    }

    /// This method is called on sign-in, sign-out, and token changes
    let tokenListenerUnsubscriber = auth.onIdTokenChanged(user => {
      /// Only fetch claims if we still have a user
      if (auth.currentUser) {
        fetchClaims()
      }
    })

    /// https://medium.com/firebase-developers/patterns-for-security-with-firebase-supercharged-custom-claims-with-firestore-and-cloud-functions-bb8f46b24e11
    let unsubscriber = firebase.firestore()
      .collection('user-claims')
      .doc(authenticatedUser.uid)
      .onSnapshot(snapshot => {
        if (!snapshot.exists) {
          return
        }
        const data = snapshot.data()
        if (data._lastCommitted) {
          if (data._lastCommitted.seconds != lastCommitted) {
            auth.currentUser.getIdToken(true)
          }
          setLastCommitted(data._lastCommitted.seconds)
        }
      })

    return () => {
      if (unsubscriber) {
        unsubscriber()
      }
      if (tokenListenerUnsubscriber) {
        tokenListenerUnsubscriber()
      }
    }
  }, [authIsLoading, authenticatedUser])

  return (
    <FeatureGroupContext.Provider value={{ isLoading, isBeta, isStaff }}>
      {children}
    </FeatureGroupContext.Provider>
  )
}

// Custom hook that shorhands the context
export const useFeatureGroup = () => useContext(FeatureGroupContext)