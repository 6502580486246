import { createContext, useContext, useEffect, useState } from 'react'
import { useAuthenticatedUser } from '@/context/authContext'
import { userConverter } from '@/models/Users'
import firebase from '@/firebase/clientApp'
import { logError } from '@/helpers/Logging'

export const UserProfileContext = createContext()
UserProfileContext.displayName = 'FirebaseUserProfileContext'

export default ({ children }) => {
  const { authIsLoading, authenticatedUser } = useAuthenticatedUser()
  const [profile, setProfile] = useState(null)
  const [loading, setIsLoading] = useState(true)
  const [error, setError] = useState(null)

  useEffect(() => {
    if (authIsLoading) {
      return
    }

    if (!authenticatedUser) {
      setIsLoading(false)
      return
    }

    try {
      setIsLoading(true)
      var unsubscribe = firebase.firestore()
        .collection('users')
        .doc(authenticatedUser.uid)
        .withConverter(userConverter)
        .onSnapshot(doc => {
          let profile = doc.data({ serverTimestamps: 'estimate' })
          setProfile(profile)
          setIsLoading(false)
        }, error => {
          throw error
        })
    } catch (error) {
      logError(error, { type: 'context', name: 'UserProfileContext' })
      setProfile(null)
      setError(error)
      setIsLoading(false)
    }
    return () => {
      if (unsubscribe) unsubscribe()
    }
  }, [authIsLoading, authenticatedUser])

  return (
    <UserProfileContext.Provider value={{ profile, loading, error }}>
      {children}
    </UserProfileContext.Provider>
  )
}

// Custom hook that shorhands the context
export const useUserProfile = () => useContext(UserProfileContext)