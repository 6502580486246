export function niceType (a) {
  if (!a || !a.length) {
    return ''
  }

  a = a.replace(/(^|[-\u2014\s(\["])'/g, "$1\u2018")       // opening singles
  a = a.replace(/'/g, "\u2019")                            // closing singles & apostrophes
  a = a.replace(/(^|[-\u2014/\[(\u2018\s])"/g, "$1\u201c") // opening doubles
  a = a.replace(/"/g, "\u201d")                            // closing doubles
  a = a.replace(/--/g, "\u2014")                           // em-dashes
  return a
}

export function truncate(str, length) {
  if (length == null || !str) {
    return str
  }
  let ending = '…'

  if (str.length > length) {
    return str.substring(0, length - ending.length) + ending
  }

  return str
}