import { useState, useEffect, createContext, useContext } from 'react'
import firebase from '@/firebase/clientApp'
import { logError } from '@/helpers/Logging'

export const AuthContext = createContext()
AuthContext.displayName = 'FirebaseAuthContext'

export default ({ children }) => {
  const [authenticatedUser, setAuthenticatedUser] = useState(null)
  const [authIsLoading, setAuthIsLoading] = useState(true)

  useEffect(() => {
    /// This tracks sign-in, sign-out, and token changes
    const unsubscriber = firebase.auth().onAuthStateChanged(async user => {
      try {
        if (user) {
          const { uid, email, photoURL } = user
          setAuthenticatedUser({ uid, email, photoURL })
          setAuthIsLoading(false)
        } else {
          setAuthenticatedUser(null)
          setAuthIsLoading(false)
        }
      } catch (error) {
        logError(error, { type: 'context', name: 'auth' })
        /// Connection error
        setAuthenticatedUser(null)
        setAuthIsLoading(false)
      }
    })
    return () => {
      unsubscriber()
    }
  }, [])

  return (
    <AuthContext.Provider value={{ authenticatedUser, authIsLoading }}>
      {children}
    </AuthContext.Provider>
  )
}

export function login () {
  firebase.auth().signInWithPopup(new firebase.auth.GoogleAuthProvider())
}

export function logout () {
  firebase.auth().signOut()
}

// Custom hook that shorhands the context
export const useAuthenticatedUser = () => useContext(AuthContext)